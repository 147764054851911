var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c(
      "header",
      [
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.createTemplate } },
          [_vm._v(" New ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "inner" },
      [
        _vm.loading
          ? _c("loader")
          : _c(
              "ul",
              _vm._l(_vm.sorted_templates, function (template) {
                return _c(
                  "li",
                  {
                    key: template.id,
                    on: { click: () => _vm.editTemplate(template.id) },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(template.title))]),
                    _c("svgicon", {
                      staticClass: "triangle",
                      attrs: { name: "triangle" },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }